import "./about.css";
import Line from "../../img/line.png"
// import AboutMeImg from "../../img/About Me Image.png"
import XRDevIcon from "../../img/VR Icon.svg"
import InteractionDesignIcon from "../../img/3D Interaction Design Icon.svg"
import SoftwareDevIcon from "../../img/Software Development Icon.svg"
import CGIcon from "../../img/3D CG Icon.svg"

// Scroll effect
import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

const About = () => {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);
    return (
        
        // a means about
        <div className="a scroll_animation" data-aos="fade-down">
            <div class="a-grid-container-single-row">
                <div class="a-grid-item-col-l-align">
                    <div className="a-flexbox-item">
                        <a id="about-anchor"><h1 className="a-title">about me</h1>
                            <img src={Line} alt="" className="a-line"></img>
                        </a>
                    </div>
                </div>
            </div>

            <div className="a-grid-container-two-cols">
                {/* C1R1 */}
                {/* <div class="a-grid-item-col-r-align avatar-column">
                    <div className="a-flexbox-item avatar-flexbox">
                        <img src={AboutMeImg} alt="" className="a-myavatar"></img>
                    </div>
                </div> */}
                <div className="a-grid-item-col-l-align">
                    <div className="a-flexbox-item">
                        <div className="a-name">Hi! Learn a little <b>about me</b> here.</div>
                    </div>
                    <div className="a-flexbox-item description-flexbox">
                        <p className="a-my-description">
                        I am a software developer specializing in creating real-time 3D applications that challenge the boundaries of interactive content. I hold a masters degree in Digital Media from the University of Central Florida, as well as a bachelors degree in Information Technology and Informatics from Rutgers University, where I graduated magna cum laude. Throughout my professional and academic career, I have honed my skills in augmented, virtual, and mixed reality development, software development, and spatial interaction design. I aim to find opportunities to unlock the full potential of interactive technology by crafting innovative applications and experiences. When I'm not programming, I enjoy playing and creating video games, watching movies, and playing piano.
                        </p>
                    </div>
                </div>
            </div>

            {/* Subject Boxes */}
            {/* XR Development and Design */}
            <div className="a-grid-container">
                <div className="a-grid-item-row">
                    <div className="a-grid-container-two-cols a-flexbox-item-rounded">
                        <div className="a-grid-item-row subjects">
                            <div className="a-flexbox-item">
                                <img src={XRDevIcon} alt="" className="a-icon"></img>
                            </div>
                        </div>
                        <div className="a-grid-item-col-l-align a-subject-and-details-col">
                            <div className="a-flexbox-item a-subject-and-details-container">
                                <div className="a-flexbox-item a-subject-container">
                                    <div className="a-subjects">XR Development</div>
                                </div>
                                <div className="a-flexbox-item a-descr-container">
                                    <div className="a-subjects-descr">prototype, design, programming<br /><br /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* 3D Interaction & Design */}
                <div className="a-grid-item-row">
                    <div className="a-grid-container-two-cols a-flexbox-item-rounded">
                        <div className="a-grid-item-row subjects">
                            <div className="a-flexbox-item">
                                <img src={InteractionDesignIcon} alt="" className="a-icon"></img>
                            </div>
                        </div>
                        <div className="a-grid-item-col-l-align a-subject-and-details-col">
                            <div className="a-flexbox-item a-subject-and-details-container">
                                <div className="a-flexbox-item a-subject-container">
                                    <div className="a-subjects">3D Interaction Design</div>
                                </div>
                                <div className="a-flexbox-item a-descr-container">
                                    <div className="a-subjects-descr">innovative spatial interactions<br /><br /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Software Development */}
                <div className="a-grid-item-row">
                    <div className="a-grid-container-two-cols a-flexbox-item-rounded">
                        <div className="a-grid-item-row subjects">
                            <div className="a-flexbox-item">
                                <img src={SoftwareDevIcon} alt="" className="a-icon"></img>
                            </div>
                        </div>
                        <div className="a-grid-item-col-l-align a-subject-and-details-col">
                            <div className="a-flexbox-item a-subject-and-details-container">
                                <div className="a-flexbox-item a-subject-container">
                                    <div className="a-subjects">Software Development</div>
                                </div>
                                <div className="a-flexbox-item a-descr-container">
                                    <div className="a-subjects-descr">games, experiences, and applications<br /><br /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Real-time Computer Graphics */}
                <div className="a-grid-item-row">
                    <div className="a-grid-container-two-cols a-flexbox-item-rounded">
                        <div className="a-grid-item-row subjects">
                            <div className="a-flexbox-item">
                                <img src={CGIcon} alt="" className="a-icon"></img>
                            </div>
                        </div>
                        <div className="a-grid-item-col-l-align a-subject-and-details-col">
                            <div className="a-flexbox-item a-subject-and-details-container">
                                <div className="a-flexbox-item a-subject-container">
                                    <div className="a-subjects">Real-time CG</div>
                                </div>
                                <div className="a-flexbox-item a-descr-container">
                                    <div className="a-subjects-descr">simulating environments, characters, and objects in the virtual world</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* SKILLS AND SOFTWARE COLUMNS */}
            <div class="a-grid-container-two-cols">
                {/* C2R4 */}
                <div class="a-grid-item-col">
                    <div className="a-flexbox-item-rounded">
                        {/* I think this is just shorthand for when you're making a variation of a class... You could hav just as easily made an entirely new className-"a-flexbox-item-a-skills-software" and copied and pasted the attributes from a-flexbox item and then added in the properties you wanted. */}
                        <div className="a-flexbox-item a-skills-software">
                            <ul className="a-skills-software-list">
                                <li><h3>My skills include...</h3></li>
                                {/* <div className="a-skills-software-title">My skills include...</div> */}
                                <li>XR Development</li>
                                <li>XR Design</li>
                                <li>Game Development</li>
                                <li>C#</li>
                                <li>Python</li>
                                <li>Java</li>
                                <li>Interaction Design</li>
                                <li>Prototyping</li>
                                <li>Data Analysis</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* C3R4 */}
                <div class="a-grid-item-col">
                    <div className="a-flexbox-item-rounded">
                        <div className="a-flexbox-item a-skills-software">
                            <ul className="a-skills-software-list">                        
                                {/* <div className="a-skills-software-title">I have software experience in...</div> */}
                                <li><h3>I have software experience in...</h3></li>
                                <li>Unity</li>
                                <li>Git</li>
                                <li>Arduino IDE</li>
                                <li>Figma</li>
                                {/*<li>ShapesXR</li>
                                <li>Blender</li> */}
                                {/* <li>Gravity Sketch</li>*/}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
