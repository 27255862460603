import React from 'react'
import "./Contact"

// Scroll effect
import { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

function EmailPopup(props) {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    var element = document.getElementsByClassName("close-btn");
    return (props.trigger) ?
    (
            <div className="popup scroll_animation" data-aos="fade-down">
            <div className="popup-inner">
                {/* Close the popup when you click this button */}
                    <button className="close-btn" onClick={() => props.setTrigger(false)}>x</button>
                    {/* <button className="close-btn" onClick={() => element.reset()}>x</button> */}

                    {/* <button className="close-btn" onClick={() => props.setTrigger(false)}>x</button> */}
                    {props.children}
                    
                    {/* {element.reset()} */}
            </div>
        </div>
        // If it's not true, don't show
        ) : "";
     
}

//   const [state, setState] = useState(false)
//     const toggle=() => {
//         setState(!state);
//     }





// function changeText() {
//     let element = document.getElementById("btn");
//     if (element.value == "Hide Result")
//         element.value = "Show Result";
//     else
//         element.value = "Hide Result";
// }





// function myFunction() {
//     document.getElementById("demo").innerHTML = "Hello World"
// }
// function changeText()
// {
//     var id = document.getElementsByName("button-with-fx")[0].id;
//     if (id == 1)
//     {
//         document.getElementByName("button-with-fx").innerHTML = "Learn Programming";
//         document.getElementsByName("button-with-fx")[0].id = 0;
//     }
//     else
//     {
//         document.getElementById("button-with-fx").innerHTML = "How to CodeSchool.com";
//         document.getElementsByName("button-with-fx")[0].id = 1;
//         }
// }

// let btnSend = document.getElementsByClassName('button-with-fx')
// let message = document.getElementsByClassName('button-with-fx')
// btnSend.addEventListener('click', () => {
//     btnSend.innerText = "Sending...";
//     setTimeout(() => {
//         btnSend.style.display = "none"
//         message.innerText="Message Sent."
//     }, 5000);

// });
// const btn = document.getElementById("btn");

// btn.addEventListener("click", () => {

//     if (btn.innerText === "Red") {
//         btn.innerText = "Blue";
//     } else {
//         btn.innerText = "Red";
//     }
// });

export default EmailPopup
