import "../Home/intro.css"
import Line from "../../img/line.png"
import Arrow from "../../img/Arrow.png"

// Scroll effect
import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'



const Intro = () => {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);
    return (
        // i means intro
        <div className="i scroll_animation" data-aos="fade-down">
            <img src={Line} alt="" className="i-border-top"></img>
            <div class="i-grid-container">
                <div class="i-grid-item-col"></div>
                <div class="i-grid-item-col"></div>
                <div class="i-grid-item-col">
                    {/* <div className="i-flexbox-item"> */}
                        {/* <div className="i-welcome"> */}
                            {/* welcome.
                            <img src={Line} alt="" className="i-line"></img> */}
                        {/* </div> */}
                    {/* </div> */}
                </div>
                <div class="i-grid-item-col"></div>
                <div class="i-grid-item-col-l-align"></div>
                <div class="i-grid-item-col-l-align"></div>
                <div class="i-grid-item-col-l-align"></div>
                <div class="i-grid-item-col">
                    {/* <div className="i-flexbox-item intro">
                        <h2 className="i-intro">Porfolio</h2>
                    </div>
                    <div className="i-flexbox-item circle">
                        <h2 className="i-circle"></h2>
                    </div> */}
                    
                    <div className="i-flexbox-item i-name-and-title">
                        <h1 className="i-name">Joslyn Parchman</h1>
                        <div className="i-title">
                            I <b>develop</b> and <b>design</b> AR/VR applications and interactive virtual experiences using real-time 3D technology.
                        </div>
                    </div>
                    <div className="i-flexbox-item blobs-container">
                        {/* <div class="blob blue"></div> */}
                        {/* <div className="i-flexbox-item arrow-box">
                            <a href="/#work-anchor" target="_self" rel="noreferrer"><img src={Arrow} alt="" className="i-arrow"></img></a>
                        </div> */}
                    </div>
                </div>
                <div class="i-grid-item-row">
                    <div className="i-flexbox-item portfolio">portfolio</div>
                </div>
            </div>
        </div>

        )
    }

export default Intro
