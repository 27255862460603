import "./footer.css";
let copyright = String.fromCodePoint(0x00A9);

export const Footer = () => {
    return (
        // f stands for footer
        <div className="f">
            <div className="f-grid-container">
                <div className="f-flexbox-item">
                    <div className="f-grid-item-row">
                        <div className="f-footer-text">
                            <div className="copyright-symbol">{copyright}</div><div className="f-copyright-note">{" Joslyn Parchman " + new Date().getFullYear() + " All Rights Reserved"}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
