import DATCHIcon from "../../img/DATCH/DATCHLogo.png"
import DATCHFeaturesDemo from "../../img/DATCH/DATCHFeaturesDemo(Short).mp4"
import MarkerPegs from "../../img/DATCH/MarkerPegsNew.png"
import UIComparison from "../../img/DATCH/UI.png"
import DATCHDrawABoxDemo from "../../img/DATCH/DATCHDrawingBoxDemo.mp4"
import DATCHirl from "../../img/DATCH/DATCHInWorld.jpeg"
import "../Home/projectPage2.css"
import Line from "../../img/line.png"
import DATCHMeetingImg from "../../img/DATCH/DATCH Meeting.jpg"
import DatchGIS from "../../img/DATCH/GIS Hardware.jpg"
import DATCHFilePicker from "../../img/DATCH/DATCHImageFilePicker.gif"
import MeasuringCube from "../../img/DATCH/Measuring Cube.gif"
import DrawingPlaneSnapping from "../../img/DATCH/Gridpoint Snapping.gif"
import ShapeSnapping from "../../img/DATCH/Shape Snapping.gif"
import DATCHLiveDemo from "../../img/DATCH/DATCH_Live_Demo.mp4"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import CardImage from "../Home/CardImage";
import CardVideo from "../Home/CardVideo";


export const DATCH = () =>
{
    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        slickPlay: true
    };

    return (
        <div className="pp2">
            <div className="pp2-grid-container">
                <div className="pp2-grid-item-col">
                    <h1 className="pp2-title">datch
                        <img src={Line} alt="" className="pp2-title-line"></img>
                    </h1>
                </div>
                <div className="pp2-grid-item-row summary-align">
                    <p className="pp2-summary">An AR drawing application for archaeologists and architects to document artifacts at excavation sites</p>
                </div>
            </div>
           
            {/* Carousel */}
            <Slider {...settings}>
                <CardVideo source={DATCHLiveDemo} type="video/mp4" />
                <CardImage source={DATCHMeetingImg} />
                <CardImage source={DatchGIS} />
            </Slider>

            <div className="pp2-grid-container">
                <div className="pp2-grid-item-row section-separator">
                    <div className="pp2-subtitle">overview</div>
                    <p className="pp2-description">
                        Documenting and Triaging Cultural Heritage (DATCH) is a GPS-enabled, augmented reality application for the Microsoft Hololens 2. DATCH allows users to draw, record, and document rocks, artifacts, and structures in indoor and outdoor research locations. The project has been tested in the Kerkenes archaeological site in Turkey and is being developed by a small team. It will later be expanded for use in other fields. An open source version of the application has been released on GitHub.
                    </p>
                    <br />
                    <p className="pp2-role">Primary responsibilities: XR development, GPS functionality, UI/UX design, and documentation.</p>
                
                </div>

                <div className="pp2-grid-item-row section-separator tags-grid">
                    <div className="pp2-subtitle">tools</div>

                    <div className="pp2-grid-container-five-col">
                        <div className="pp2-grid-item-col"><div className="tag">UNITY</div></div>
                        <div className="pp2-grid-item-col"><div className="tag">MRTK</div></div>
                        <div className="pp2-grid-item-col"><div className="tag">GIS</div></div>
                        <div className="pp2-grid-item-col"><div className="tag">ARDUINO</div></div>
                        <div className="pp2-grid-item-col"><div className="tag">GITHUB</div></div>
                        <div className="pp2-grid-item-col"><div className="tag">PHOTOSHOP</div></div>
                        <div className="pp2-grid-item-col"><div className="tag">ILLUSTRATOR</div></div>
                        <div className="pp2-grid-item-col"><div className="tag">TRELLO</div></div>
                    </div>
                </div>

                <div className="pp2-grid-item-row section-separator">
                    <div className="pp2-subtitle">links</div>

                    {/* <div className="pp2-grid-item-row links-row"> */}
                    <div className="links-row">
                        <div className="pp-flexbox-item button-flex">
                            <form action="https://sciences.ucf.edu/anthropology/datch/" method="get" target="_blank">
                                <button type="submit">Website</button>
                            </form>
                        </div>
                        <div className="pp-flexbox-item button-flex">
                            <form action="https://github.com/datch-ucf/datch2-docs" method="get" target="_blank">
                                <button type="submit">Documentation</button>
                            </form>
                        </div>
                        <div className="pp-flexbox-item button-flex">
                            <form action="https://github.com/datch-ucf/datch2" method="get" target="_blank">
                                <button type="submit">Open Source</button>
                            </form>
                        </div>
                    </div>
                </div>

                {/* C1R7 - Back */}
                <div class="pp2-grid-item-row back">
                    <div className="pp2-subtitle">
                    <a href="/" target="_self" className="pp2-back">back
                        <img src={Line} alt="" className="pp2-back-line"></img>
                    </a>
                    </div>
                </div>
            </div>
        </div>
    )
};