import "../Home/projectPage2.css"
import Line from "../../img/line.png"
import CandleChaosVideo from "../../img/Candle Chaos/Candle Chaos Video.mp4"
import CandleChaosScreenshot from "../../img/Candle Chaos/Piggy's Candle Chaos Screenshot.png"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import CardImage from "../Home/CardImage";
import CardVideo from "../Home/CardVideo";


export const CandleChaos = () =>
{
    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        slickPlay: true
    };

    return (
        <div className="pp2">
            <div className="pp2-grid-container">
                <div className="pp2-grid-item-col">
                    <h1 className="pp2-title">candle chaos
                        <img src={Line} alt="" className="pp2-title-line"></img>
                    </h1>
                </div>
                <div className="pp2-grid-item-row summary-align">
                    <p className="pp2-summary">A third-person shooter minigame where the player must shoot enough candles into a cake within the time limit</p>
                </div>
            </div>
           
            {/* Carousel */}
            <Slider {...settings}>
                <CardVideo source={CandleChaosVideo} type="video/mp4" />
            </Slider>

            <div className="pp2-grid-container">
                <div className="pp2-grid-item-row section-separator">
                    <div className="pp2-subtitle">overview</div>
                    <p className="pp2-description">
                    Piggy's Candle Chaos is a Mario Party-inspired third-person shooter minigame where the player, a pig chef, must shoot enough candles into a cake within the alotted time limit. The game was created by a team of two developers for a short game jam.
                    </p>
                    <br />
                    <p className="pp2-role">Primary responsibilities: Game development, Game design.</p>
                
                </div>

                <div className="pp2-grid-item-row section-separator tags-grid">
                    <div className="pp2-subtitle">tools</div>

                    <div className="pp2-grid-container-five-col">
                        <div className="pp2-grid-item-col"><div className="tag">UNITY</div></div>
                        <div className="pp2-grid-item-col"><div className="tag">VISUAL SCRIPTING</div></div>
                        <div className="pp2-grid-item-col"><div className="tag">GITHUB</div></div>
                    </div>
                </div>

                {/* C1R7 - Back */}
                <div class="pp2-grid-item-row back">
                    <div className="pp2-subtitle">
                    <a href="/" target="_self" className="pp2-back">back
                        <img src={Line} alt="" className="pp2-back-line"></img>
                    </a>
                    </div>
                </div>
            </div>
        </div>
    )
};