// import Intro from "../components/intro/Intro";
// import About from "../components/about/About";
// import Contact from "../components/contact/Contact";
// import ProductList from "../components/productList/ProductList";
// import FlexboxTest from "../Home/FlexboxTest";
import Intro from "../Home/Intro";
import Projects from "../Home/Projects"
import About from "../Home/About"
import Contact from "../Home/Contact"
import "./home.css";
// import Projects from "/Users/Joslyn/GitHub/Portfolio Website/portfolio/src/"


export const Home = () =>
{
    return (
        <div>
            {/* <Intro />
            <About />
            <ProductList />
            <Contact /> */}

            {/* <FlexboxTest/> */}
            <Intro />
            <Projects />
            <About />
            <Contact />
            
            

        </div>
    ) 
};

