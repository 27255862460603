import OceanaMain from "../../img/Oceana Artifact/Manta.png"
import OceanaIRL from "../../img/Oceana Artifact/UCF Celebrates the Arts Comp.jpg"
import KelpForest from "../../img/Oceana Artifact/Kelp Forest1.png"
import OceanaMap from "../../img/Oceana Artifact/Map.jpg"
import JTMPrototype from "../../img/Oceana Artifact/JTMPrototype.png"
import UserFlow from "../../img/Oceana Artifact/UserFlow.png"
import OceanaSummaryVid from "../../img/Oceana Artifact/Oceana Summary (All Acts).mp4"
import MyPrototype from "../../img/Oceana Artifact/Studio 1 Project Prototype.mp4"
import OceanaVid from "../../img/Oceana Artifact/Oceana (Clip 2 with Scanning Shortened).mp4"
import "../Home/projectPage2.css"
import Line from "../../img/line.png"
import ModalImage from "react-modal-image";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import CardImage from "../Home/CardImage";
import CardVideo from "../Home/CardVideo";


export const OceanaXR = () =>
{
    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        slickPlay: true
    };

    return (
        <div className="pp2">
            <div className="pp2-grid-container">
                <div className="pp2-grid-item-col">
                    <h1 className="pp2-title">oceana xr
                        <img src={Line} alt="" className="pp2-title-line"></img>
                    </h1>
                </div>
                <div className="pp2-grid-item-row summary-align">
                    <p className="pp2-summary">A live-orchestral XR experience focused on spreading awareness of noise pollution in Earth's oceans</p>
                </div>
            </div>
           
            {/* Carousel */}
            <Slider {...settings}>
                <CardVideo source={OceanaVid} type="video/mp4"/>
                <CardImage source={OceanaIRL} />
                <CardVideo source={OceanaSummaryVid} type="video/mp4" />
                <CardVideo source={MyPrototype} type="video/mp4" />
            </Slider>

            <div className="pp2-grid-container">
                <div className="pp2-grid-item-row section-separator">
                    <div className="pp2-subtitle">overview</div>
                    <p className="pp2-description">
                    An XR experience for a live orchestral performance of a muscial composition titled, "Oceana," this experience accompanies the music's focus on noise pollution and was my master's degree final project. Developed for Oculus Quest and Microsoft Hololens 2, the project debuted during a performance at The University of Central Florida's UCF Celebrates the Arts event at Dr. Phillips Center for the Performing Arts Steinmetz Hall in April 2022.
                    </p>
                    <br />
                    <p className="pp2-role">Primary responsibilities: VR development, XR design</p>
                
                </div>

                <div className="pp2-grid-item-row section-separator tags-grid">
                    <div className="pp2-subtitle">tools</div>

                    <div className="pp2-grid-container-five-col">
                        <div className="pp2-grid-item-col"><div className="tag">UNITY</div></div>
                        <div className="pp2-grid-item-col"><div className="tag">XRIT</div></div>
                        <div className="pp2-grid-item-col"><div className="tag">GITHUB</div></div>
                        <div className="pp2-grid-item-col"><div className="tag">BLENDER</div></div>
                        <div className="pp2-grid-item-col"><div className="tag">PHOTOSHOP</div></div>
                        <div className="pp2-grid-item-col"><div className="tag">MIRO</div></div>
                    </div>
                </div>

                <div className="pp2-grid-item-row section-separator">
                    <div className="pp2-subtitle">links</div>

                    {/* <div className="pp2-grid-item-row links-row"> */}
                    <div className="links-row">
                        <div className="pp-flexbox-item button-flex links-flex">
                            <form action="https://joslynparchman7.wixsite.com/oceana-xr" method="get" target="_blank">
                                <button type="submit">Visit Site</button>
                            </form>
                        </div>
                    </div>
                </div>

                {/* C1R7 - Back */}
                <div class="pp2-grid-item-row back">
                    <div className="pp2-subtitle">
                    <a href="/" target="_self" className="pp2-back">back
                        <img src={Line} alt="" className="pp2-back-line"></img>
                    </a>
                    </div>
                </div>
            </div>
        </div>
    )
};