import "./contact.css";
import Line2 from "../../img/line_white.png"
import Line1 from "../../img/line.png"
// import Email from "../../img/email2.png"
// import LinkedIn from "../../img/linkedin2.png"
// import GitHub from "../../img/github2.png"
import Email from "../../img/email.png"
import LinkedIn from "../../img/linkedin.svg"
import GitHub from "../../img/github.svg"
import EmailPopup from "./EmailPopup"
import { useContext, useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import { ThemeContext } from "../../context";

const Contact = () => {

    // EMAIL POPUP
    // Variable for email popup. Popup is false at beginning
    const [buttonPopup, setButtonPopup] = useState(false);
    // const[]



    const [state, setState] = useState(false)
    const toggle=() => {
        setState(!state);
        alert("Email sent. Thanks!");
    }
    
    // Variables for email popup page
    const formRef = useRef();
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;
    

    // Creating a useState for after a form has been sent.
    // After successfully sending a message, we will set useState to true
    const [done, setDone] = useState(false)

    // EMAIL SUBMISSION FORM
    // e means event. This is the variable we are passing into the function (presumably)
    const handleSubmit = (e) => {
        // Use this function to ensure that when you click the Submit button, it doesn't refresh the page.
        e.preventDefault();

        // Instead, we are going to do this when we submit the data
        // Copied and pasted from https://www.emailjs.com/docs/sdk/send-form/ after installing the emailjs package
        // Package installation instructions are here https://www.emailjs.com/docs/sdk/installation/
        // Be sure to paste service id, template id, etc. from EmailJS site
        // Can't find the user id referred to in the tutorial. Latest documentation says to use the public key instead (if you didn't use the init function).
        emailjs.sendForm('service_c5aniic', 'template_i8ezwoi', formRef.current, '4Tj-gRTlKmcwFGd8Q')
            .then((result) => {
                console.log(result.text);
                // After successfully sending a message, set useState to true
                setDone(true)
            }, (error) => {
                console.log(error.text);
            });

    };

    function codingCourse() {
        console.log("freeCodeCamp");
    }

    return (
        <div className="c">
            <div class="c-grid-container">

                {/* C1R1 */}
                {/* <div class="c-grid-item-col"></div> */}

                {/* C2R1 */}
                <div class="c-grid-item-col">
                    <div className="c-flexbox-item title">
                        <a id="contact-anchor"><h1 className="c-title">contact me</h1></a>
                    </div>
                    <div className="c-flexbox-item c-line-container">
                        <img src={Line1} alt="" className="c-title-line"></img>
                    </div>
                </div>


                {/* C2R2 */}
                <div class="c-grid-item-row socials">
                    <div className="c-flexbox-item email">
                        <img onClick={() => setButtonPopup(true)} src={Email} alt="" className="c-social-icons email-icon"></img>
                    </div>
                    <div className="c-flexbox-item email">
                        <a href="https://www.linkedin.com/in/joslyn-parchman" target="_blank" rel="noreferrer"><img src={LinkedIn} alt="" className="c-social-icons"></img></a>
                    </div>
                    <div className="c-flexbox-item email">
                        <a href="https://github.com/JoslynP1" target="_blank" rel="noreferrer"><img src={GitHub} alt="" className="c-social-icons"></img></a>
                    </div>
                </div>
                {/* C3R2 */}
                {/* <div class="c-grid-item-row">jfiso</div> */}
            </div>

            {/* Email Popup Page */}
            {/* Show popup on click of this button */}
            {/* <button onClick={() => setButtonPopup(true)}>Open Popup</button> */}

            <EmailPopup trigger={buttonPopup} setTrigger={setButtonPopup}>

                <div className="c-grid-container-two-cols">
                    <div className="c-grid-item-col modal-color-half">
                        <div className="c-flexbox-item">
                                <div className="c-grid-item-col-left-align">
                                    <div className="c-flexbox-item email-text">
                                        <p className="c-email-popup-subtitle"><b>Want to get in touch?</b></p>
                                        <p className="c-email-popup-subtitle2">Feel free to contact me.</p>
                                        </div>
                                        {/* If we are in darkMode, change the color to #333 */}
                                        {/* <div className="c-flexbox-item">
                                            <input style={{ backgroundColor: darkMode && "#333" }} type="text" placeholder="Name" name="user_name" />
                                        </div>
                                        <div className="c-flexbox-item">
                                            <input style={{ backgroundColor: darkMode && "#333" }} type="text" placeholder="Subject" name="user_subject" />
                                        </div>
                                        <div className="c-flexbox-item">
                                            <input style={{ backgroundColor: darkMode && "#333" }} type="text" placeholder="Email" name="user_email" />
                                        </div> */}
                                    </div>
                                {/* </div> */}
                            {/* </form> */}
                        </div>
                    </div>

                    <div className="c-grid-item-col-rightside">
                        <form ref={formRef} onSubmit={handleSubmit}>

                            <div className="c-flexbox-item">
                                <input style={{ backgroundColor: darkMode && "#333" }} type="text" placeholder="Name" name="user_name" />
                            </div>
                            <div className="c-flexbox-item">
                                <input style={{ backgroundColor: darkMode && "#333" }} type="text" placeholder="Subject" name="user_subject" />
                            </div>
                            <div className="c-flexbox-item">
                                <input style={{ backgroundColor: darkMode && "#333" }} type="text" placeholder="Email" name="user_email" />
                            </div>
                            <div className="c-flexbox-item">
                                <textarea style={{ backgroundColor: darkMode && "#333" }} rows="5" placeholder="Message" name="message" />
                            </div>
                            <div className="c-flexbox-item">
                                <button onClick={toggle} className="button-with-fx">Send</button>

                                {/* if done is true, write a thank you message. You can also use if statements if preferred. */}
                                <div className="thx-mssg">{done}</div>

                            </div>
                            {/* {alert("Thanks")} */}

                        </form>
                        
                    </div>
                    
                </div>
            </EmailPopup>  

        </div>
        
    );
};

export default Contact