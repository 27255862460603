import "./projects.css";
import Line from "../../img/line.png"
import DATCHThumbnail from "../../img/DATCH/DATCHInWorld.jpeg"
import _3duiThumbnail from "../../img/3DUI Projects/3DUIFruitNinja.png"
import VREThumbnail from "../../img/VR Engineering Projects/VRESeriesThumbnail.png"
import GigaGolfThumbnail from "../../img/Giga Golf/GigaGolfThumbnail.png"
import CatbotThumbnail from "../../img/Catbot/CatbotConvoScreenshot.png"
import CandleChaosThumbnail from "../../img/Candle Chaos/Piggy's Candle Chaos Screenshot.png"
import DiscoDiffusionThumbnail from "../../img/DiscoDiffusionProject/Subject 3/Image 7 - Beluga Whale, Akihito Tsukushi.png"
import EscapeRoomGameThumbnail from "../../img/Text-Based Escape Room Game/PexelsBunker.jpeg"
import OceanaIRLThumbnail from "../../img/Oceana Artifact/UCF Celebrates the Arts Comp.jpg"
    
import { Link } from "react-router-dom";

// Scroll effect
import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

const Projects = () => {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);
    return (
        // p stands for projects
        <div className="p scroll_animation" data-aos="fade-down">
            <div class="p-grid-container all-projects-grid">
                <div class="p-grid-item-col-l-align projects-title-bg">
                    <div className="p-flexbox-item">
                        <div className="p-projects">
                            <a id="work-anchor"><h1 className="p-projects">work</h1>
                                <img src={Line} alt="" className="p-pro-line"></img>
                            </a>
                        </div>
                    </div>
                </div>
                {/* Empty */}
                <div className="p-grid-item-col"></div>


                {/* C1R2: DATCH */}
                <div className="p-grid-item-col">
                    <div className="p-grid-container p-card-grid-container">
                        <div className="p-grid-item-col p-project-card-col">
                            <img src={DATCHThumbnail} alt="" className="p-thumbnail datch-thumbnail-pos"></img> 
                        </div>

                        <div className="p-grid-item-col-l-align project-info">
                            <div className="p-flexbox-item">
                                <div className="p-title">datch</div>
                            </div>
                            <div className="p-flexbox-item descr">
                                <p className="p-descr">An AR drawing application for archaeologists and architects to document artifacts at excavation sites</p>
                            </div>
                            <div className="p-flexbox-item link">
                                <Link to="/datch"><p className="p-see-proj" onClick={() => { window.scroll(0, 0); }}>see project</p></Link>
                                <img src={Line} alt="" className="p-see-proj-line"></img>
                            </div>   
                        </div>
                    </div>
                </div>
    
                {/* C2R2 Oceana XR */}

                <div className="p-grid-item-col">
                    <div class="p-grid-container p-card-grid-container">
                        <div className="p-grid-item-col p-project-card-col">
                            {/* Not sure how to position this properly with image inside a flexbox... */}
                            <img src={OceanaIRLThumbnail} alt="" className="p-thumbnail"></img>
                        </div>

                        <div className="p-grid-item-col-l-align project-info">
                            <div className="p-flexbox-item">
                                <div className="p-title">oceana xr</div>
                            </div>
                            <div className="p-flexbox-item descr">
                                <p className="p-descr">A live-orchestral XR experience focused on spreading awareness of noise pollution</p>
                            </div>
                            <div className="p-flexbox-item">
                                <Link to="/oceanaxr"><p className="p-see-proj" onClick={() => { window.scroll(0, 0); }}>see project</p></Link>
                                <img src={Line} alt="" className="p-see-proj-line"></img>
                            </div>
                        </div>
                    </div>
                </div>
                
                {/* C1R3 3DUI Design Minigames*/}
                {/* <div className="p-grid-item-col">
                    <div class="p-grid-container p-card-grid-container">
                        <div className="p-grid-item-col p-project-card-col">
                            <img src={_3duiThumbnail} alt="" className="p-thumbnail _3dui-thumbnail-pos"></img>
                        </div>

                        <div className="p-grid-item-col-l-align project-info">
                            <div className="p-flexbox-item">
                                <div className="p-title">3dui design minigames</div>
                            </div>
                            <div className="p-flexbox-item descr">
                                <p className="p-descr">Accelerated micro-projects used to explore 3D user interface design</p>
                            </div>
                            <div className="p-flexbox-item">
                                <Link to="/Project3"><p className="p-see-proj" onClick={() => { window.scroll(0, 0); }}>see project</p></Link>
                                <img src={Line} alt="" className="p-see-proj-line"></img>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* C2R3 VR Engineering Project Series */}
                <div className="p-grid-item-col">
                    <div class="p-grid-container p-card-grid-container">
                        <div className="p-grid-item-col p-project-card-col">
                            {/* Not sure how to position this properly with image inside a flexbox... */}
                            <img src={VREThumbnail} alt="" className="p-thumbnail vre-thumbnail-pos"></img>
                        </div>

                        <div className="p-grid-item-col-l-align project-info">
                            <div className="p-flexbox-item">
                                <div className="p-title">vr engineering project series</div>
                            </div>
                            <div className="p-flexbox-item descr">
                                <p className="p-descr">A modular project set that explores universal functions and techniques in VR development</p>
                            </div>
                            <div className="p-flexbox-item">
                                <Link to="/vrengineeringseries"><p className="p-see-proj" onClick={() => { window.scroll(0, 0); }}>see project</p></Link>
                                <img src={Line} alt="" className="p-see-proj-line"></img>
                            </div>
                        </div>
                    </div>
                </div>

                {/* C2R3 Candle Chaos */}
                <div className="p-grid-item-col">
                    <div class="p-grid-container p-card-grid-container">
                        <div className="p-grid-item-col p-project-card-col">
                            <img src={CandleChaosThumbnail} alt="" className="p-thumbnail candle-chaos-thumbnail-pos"></img>
                        </div>

                        <div className="p-grid-item-col-l-align project-info">
                            <div className="p-flexbox-item">
                                <div className="p-title">candle chaos</div>
                            </div>
                            <div className="p-flexbox-item descr">
                                <p className="p-descr">A third-person shooter minigame where the player must shoot enough candles into a cake within the time limit</p>
                            </div>
                            <div className="p-flexbox-item">
                                <Link to="/candlechaos"><p className="p-see-proj" onClick={() => { window.scroll(0, 0); }}>see project</p></Link>
                                <img src={Line} alt="" className="p-see-proj-line"></img>
                            </div>
                        </div>
                    </div>
                </div>

                {/* C2R3 Giga Golf */}
                <div className="p-grid-item-col">
                    <div class="p-grid-container p-card-grid-container">
                        <div className="p-grid-item-col p-project-card-col">
                            <img src={GigaGolfThumbnail} alt="" className="p-thumbnail giga-golf-thumbnail-pos"></img>
                        </div>

                        <div className="p-grid-item-col-l-align project-info">
                            <div className="p-flexbox-item">
                                <div className="p-title">giga golf</div>
                            </div>
                            <div className="p-flexbox-item descr">
                                <p className="p-descr">A golf minigame created for the Spatial platform that features a twist on the classic sport</p>
                            </div>
                            <div className="p-flexbox-item">
                                <Link to="/gigagolf"><p className="p-see-proj" onClick={() => { window.scroll(0, 0); }}>see project</p></Link>
                                <img src={Line} alt="" className="p-see-proj-line"></img>
                            </div>
                        </div>
                    </div>
                </div>

                {/* C2R3 Catbot */}
                <div className="p-grid-item-col">
                    <div class="p-grid-container p-card-grid-container">
                        <div className="p-grid-item-col p-project-card-col">
                            <img src={CatbotThumbnail} alt="" className="p-thumbnail catbot-thumbnail-pos"></img>
                        </div>

                        <div className="p-grid-item-col-l-align project-info">
                            <div className="p-flexbox-item">
                                <div className="p-title">catbot</div>
                            </div>
                            <div className="p-flexbox-item descr">
                                <p className="p-descr">A chatbot created for the Spatial platform to introduce new users to some of the platform's features</p>
                            </div>
                            <div className="p-flexbox-item">
                                <Link to="/catbot"><p className="p-see-proj" onClick={() => { window.scroll(0, 0); }}>see project</p></Link>
                                <img src={Line} alt="" className="p-see-proj-line"></img>
                            </div>
                        </div>
                    </div>
                </div>

                {/* C1R4 Disco Diffusion */}
                {/* <div className="p-grid-item-col">
                    <div class="p-grid-container p-card-grid-container">
                        <div className="p-grid-item-col p-project-card-col">
                            <img src={DiscoDiffusionThumbnail} alt="" className="p-thumbnail"></img>
                        </div>

                        <div className="p-grid-item-col-l-align project-info">
                            <div className="p-flexbox-item">
                                <div className="p-title">ai image generation</div>
                            </div>
                            <div className="p-flexbox-item descr">
                                <p className="p-descr">A collection of AI-generated visual works centered around the theme of oceanic life</p>
                            </div>
                            <div className="p-flexbox-item">
                                <Link to="/Project5"><p className="p-see-proj" onClick={() => { window.scroll(0, 0); }}>see project</p></Link>
                                <img src={Line} alt="" className="p-see-proj-line"></img>
                            </div>
                        </div>
                    </div>
                </div> */}
                
                {/* C2R4 Text-Based Escape Room Game */}
                <div className="p-grid-item-col">
                    <div class="p-grid-container p-card-grid-container">
                        <div className="p-grid-item-col p-project-card-col">
                            {/* Not sure how to position this properly with image inside a flexbox... */}
                            <img src={EscapeRoomGameThumbnail} alt="" className="p-thumbnail"></img>
                        </div>

                        <div className="p-grid-item-col-l-align project-info">
                            <div className="p-flexbox-item">
                                <div className="p-title">puzzle prison game</div>
                            </div>
                            <div className="p-flexbox-item descr">
                                <p className="p-descr">A text-based game where the player must solve word, trivia, and rhyme puzzles to escape an underground bunker</p>
                            </div>
                            <div className="p-flexbox-item">
                                <Link to="/puzzleprison"><p className="p-see-proj" onClick={() => { window.scroll(0, 0); }}>see project</p></Link>
                                <img src={Line} alt="" className="p-see-proj-line"></img>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Removing personal projects for now... */}
                {/* C2R5 Personal Projects */}
                {/* <div class="p-grid-item-col-r-align">
                    <div className="p-flexbox-item p-title-margin-r">
                        <div className="p-projects">
                            personal projects
                              <div className="p-flexbox-item personal">
                                <img src={Line} alt="" className="p-personal-line"></img>
                            </div>
                        </div>
                    </div>
                </div> */}
                
            </div>
            {/* C2R5 Personal Projects */}
            {/* <div class="p-grid-container-single-row">
                <div class="p-grid-item-col">
                    <div className="p-flexbox-item">
                        <div className="p-title">
                            coming soon!
                        </div>
                    </div>
                    <div className="p-flexbox-item coming-soon-line-container">
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default Projects
