import EscapeRoomScreenshot from "../../img/Text-Based Escape Room Game/Escape Room Game Screenshot.png"
import BunkerImg from "../../img/Text-Based Escape Room Game/PexelsBunker.jpeg"
import SummaryVid from "../../img/Text-Based Escape Room Game/Escape Room Game Video.mp4"
import "../Home/projectPage.css"
import Line from "../../img/line.png"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import CardImage from "../Home/CardImage";
import CardVideo from "../Home/CardVideo";


export const PuzzlePrison = () =>
{
    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        slickPlay: true
    };

    return (
        <div className="pp2">
            <div className="pp2-grid-container">
                <div className="pp2-grid-item-col">
                    <h1 className="pp2-title">puzzle prison
                        <img src={Line} alt="" className="pp2-title-line"></img>
                    </h1>
                </div>
                <div className="pp2-grid-item-row summary-align">
                    <p className="pp2-summary">A text-based game where the player must solve word, trivia, and rhyme puzzles to escape an underground bunker</p>
                </div>
            </div>
           
            {/* Carousel */}
            <Slider {...settings}>
                <CardVideo source={SummaryVid} type="video/mp4" />
                {/* <CardImage source={BunkerImg} /> */}
            </Slider>

            <div className="pp2-grid-container">
                <div className="pp2-grid-item-row section-separator">
                    <div className="pp2-subtitle">overview</div>
                    <p className="pp2-description">
                    The game was created as part of a graduate-level Programming for Digital Media course. The premise of the game is that the player has awoken in an underground bunker with no memory of how they arrived. They must solve the lockbox puzzles distributed around the room, which unlock key items that they may use or combine to escape.
                    The game is procedural and uses an external file for specifc puzzles, as well as the Datamuse API for the rhyming and word association puzzles, and the Open Trivia Database API for true or false and multiple choice questions.
                    </p>
                    <br />
                    <p className="pp2-role">Primary responsibilities: Development, design.</p>
                
                </div>

                <div className="pp2-grid-item-row section-separator tags-grid">
                    <div className="pp2-subtitle">tools</div>

                    <div className="pp2-grid-container-five-col">
                        <div className="pp2-grid-item-col"><div className="tag">PYTHON</div></div>
                        <div className="pp2-grid-item-col"><div className="tag">GITHUB</div></div>
                    </div>
                </div>

                <div className="pp2-grid-item-row section-separator">
                    <div className="pp2-subtitle">demo</div>

                    {/* <div className="pp2-grid-item-row links-row"> */}
                    {/* <div className="links-row"> */}
                        {/* Run code only, only show output (Trinket.io website) MUST CREATE A PYTHON3 TRINKET!*/}
                    <iframe src="https://trinket.io/embed/python3/6f8937510c?outputOnly=true&runOption=run&start=result" width="100%" height="356" frameborder="0" marginwidth="0" marginheight="0" allowfullscreen></iframe>
                    {/* </div> */}
                </div>

                {/* C1R7 - Back */}
                <div class="pp2-grid-item-row back">
                    <div className="pp2-subtitle">
                    <a href="/" target="_self" className="pp2-back">back
                        <img src={Line} alt="" className="pp2-back-line"></img>
                    </a>
                    </div>
                </div>
            </div>
        </div>
    )
};