import { Link } from 'react-router-dom';
import "./navbar.css";
// import Home from "../src/img/home.png";

export const Navbar = () => {
    return (
        // n stands for navbar
        <div className="n">
            <div className="n-grid-container home">

                <div className="n-grid-item-col">
                    <div className="n-flexbox-item initials">
                        <a href="/" target="_self" className="i-initials">jp.</a>
                    </div>
                </div>
            </div>
            <div className="n-grid-container">
                
                <div className="n-grid-item-col">
                    {/* <div className="n-flexbox-item initials">
                        <a href="/" target="_self" className="i-initials">jp.</a>
                    </div> */}
                    <div className="n-flexbox-item">
                        <a href="/#work-anchor" target="_self" className="work-link nav-link">work</a>
                    </div>
                    <div className="n-flexbox-item">
                        <a href="/#about-anchor" target="_self" className="about-link nav-link">about</a>
                    </div>
                    <div className="n-flexbox-item">
                        <a href="/#contact-anchor" target="_self" className="contact-link nav-link">contact</a>
                    </div>
                </div>
                <div className="n-grid-item-col"></div>
                <div className="n-grid-item-col"></div>
                <div className="n-grid-item-col">
                    {/* <div className="n-flexbox-item-dark-mode">
                        <div className="mode-switch"><Link to="/">darkmode</Link></div>
                    </div> */}
                </div>
            </div>
        </div>
        )
}
