import VRESeriesMain from "../../img/VR Engineering Projects/VRESeriesThumbnail.png"
import DevImgPlaceholder from "../../img/VR Engineering Projects/Placeholder.png"
import Vid1 from "../../img/VR Engineering Projects/VR Engineering Projects (muted)/CAP 5115 Homework 1 Video.mp4"
import Vid2 from "../../img/VR Engineering Projects/VR Engineering Projects (muted)/CAP 5115 Homework 2 Video.mp4"
import Vid3 from "../../img/VR Engineering Projects/VR Engineering Projects (muted)/CAP 5115 Homework 3 Video.mp4"
import Vid4 from "../../img/VR Engineering Projects/VR Engineering Projects (muted)/CAP 5115 Homework 4.mp4"
import Vid5 from "../../img/VR Engineering Projects/VR Engineering Projects (muted)/CAP 5115 Homework 5.mp4"
import Vid6 from "../../img/VR Engineering Projects/VR Engineering Projects (muted)/CAP 5115 Homework 6.mp4"
import "../Home/projectPage2.css"
import Line from "../../img/line.png"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import CardImage from "../Home/CardImage";
import CardVideo from "../Home/CardVideo";


export const VREngineeringSeries = () =>
{
    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        slickPlay: true
    };

    return (
        <div className="pp2">
            <div className="pp2-grid-container">
                <div className="pp2-grid-item-col">
                    <h1 className="pp2-title long-title">vr engineering series
                        <img src={Line} alt="" className="pp2-title-line"></img>
                    </h1>
                </div>
                <div className="pp2-grid-item-row summary-align">
                    <p className="pp2-summary">A modular project set that explores universal functions and techniques in VR development</p>
                </div>
            </div>
           
            {/* Carousel */}
            <Slider {...settings}>
                <CardVideo source={Vid1} type="video/mp4" />
                <CardVideo source={Vid2} type="video/mp4" />
                <CardVideo source={Vid3} type="video/mp4" />
                <CardVideo source={Vid4} type="video/mp4" />
                <CardVideo source={Vid5} type="video/mp4" />
                <CardVideo source={Vid6} type="video/mp4" />
            </Slider>

            <div className="pp2-grid-container">
                <div className="pp2-grid-item-row section-separator">
                    <div className="pp2-subtitle">overview</div>
                    <p className="pp2-description">
                    The following is a series of project milestones from a graduate level VR Engineering course focused on implementing common functions and techniques in virtual reality development. Each part of the series builds onto the previous to create a comprehensive, interactive experience that demonstrates the core aspects of VR development including creating realistic virtual environments, travel techniques, manipulation techniques, physics-based interactions, animation-based interactions, and functional menu interfaces.
                    </p>
                    <br />
                    <p className="pp2-role">Primary responsibilities: VR development, VR design</p>
                
                </div>

                <div className="pp2-grid-item-row section-separator tags-grid">
                    <div className="pp2-subtitle">tools</div>

                    <div className="pp2-grid-container-five-col">
                        <div className="pp2-grid-item-col"><div className="tag">UNITY</div></div>
                        <div className="pp2-grid-item-col"><div className="tag">XRIT</div></div>
                        <div className="pp2-grid-item-col"><div className="tag">BLENDER</div></div>
                        <div className="pp2-grid-item-col"><div className="tag">PHOTOSHOP</div></div>
                    </div>
                </div>

                {/* C1R7 - Back */}
                <div class="pp2-grid-item-row back">
                    <div className="pp2-subtitle">
                    <a href="/" target="_self" className="pp2-back">back
                        <img src={Line} alt="" className="pp2-back-line"></img>
                    </a>
                    </div>
                </div>
            </div>
        </div>
    )
};