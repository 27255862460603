import { useContext } from "react";
import { ThemeContext } from "./context";
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { Home } from "./pages/Home/Home";
import { DATCH } from "./pages/ProjectPages/DATCH2";
import { OceanaXR } from "./pages/ProjectPages/OceanaXR2";
import { VREngineeringSeries } from "./pages/ProjectPages/VREngineeringSeries2";
import { GigaGolf } from "./pages/ProjectPages/GigaGolf";
import { Catbot } from "./pages/ProjectPages/Catbot";
import { CandleChaos } from "./pages/ProjectPages/CandleChaos";
// import { Project4 } from "./pages/ProjectPages/VREngineeringSeries";
// import { Project5 } from "./pages/ProjectPages/DiscoDiffusion";
import { PuzzlePrison } from "./pages/ProjectPages/PuzzlePrison2";
import { Navbar } from "./Navbar";
import { Footer } from "./Footer";
import "../src/App.css"



// Loading Screen
import React, { useState, useEffect } from 'react';
import MoonLoader from "react-spinners/MoonLoader";


const App = () => {

  // Loading screen
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, []) 

  const theme = useContext(ThemeContext)
  const darkMode = theme.state.darkMode;

  return (
    <div className="App">
      {loading ? (
        <div className="Loader">
          {/* <MoonLoader color={"blue"} loading={loading} margin={10} size={200} speedMultiplier={0.75} /> */}
          <MoonLoader color={"#0077b6"} loading={loading} margin={10} size={200} speedMultiplier={0.75} />
          {/* <MoonLoader color={"#c7d8e9"} loading={loading} margin={10} size={200} speedMultiplier={0.75} /> */}
        <div className="welcome-text">welcome.</div>
    </div>

        
      ) : (
        // {/* Router defines where you want access to react-router-dom stuff */}
        <Router>
          <Navbar />
          {/* Routes defines all the routes */}
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/menu" element={<Menu/>}/> */}
            <Route path="/datch" element={<DATCH />} />
            <Route path="/oceanaxr" element={<OceanaXR />} />
            <Route path="/vrengineeringseries" element={<VREngineeringSeries />} />
            <Route path="/gigagolf" element={<GigaGolf />} />
            <Route path="/catbot" element={<Catbot />} />
            <Route path="/candlechaos" element={<CandleChaos />} />
            {/* <Route path="/project4" element={<Project4 />} /> */}
            {/* <Route path="/project5" element={<Project5 />} /> */}
            <Route path="/puzzleprison" element={<PuzzlePrison />} />
            <Route path="*" element={<h1>PAGE NOT FOUND</h1>} />
          </Routes>
          <Footer />
          </Router>
          )
      // </div>
      }
    </div>
  );
};

export default App;