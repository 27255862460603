import "../Home/projectPage2.css"
import Line from "../../img/line.png"
import GigaGolfVideo from "../../img/Giga Golf/Giga Golf Clip.mp4"
import GigaGolfScreenshot from "../../img/Giga Golf/GigaGolfThumbnail.png"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import CardImage from "../Home/CardImage";
import CardVideo from "../Home/CardVideo";


export const GigaGolf = () =>
{
    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        slickPlay: true
    };

    return (
        <div className="pp2">
            <div className="pp2-grid-container">
                <div className="pp2-grid-item-col">
                    <h1 className="pp2-title">giga golf
                        <img src={Line} alt="" className="pp2-title-line"></img>
                    </h1>
                </div>
                <div className="pp2-grid-item-row summary-align">
                    <p className="pp2-summary">A golf minigame created for the Spatial platform that features a twist on the classic sport</p>
                </div>
            </div>
           
            {/* Carousel */}
            <Slider {...settings}>
                <CardVideo source={GigaGolfVideo} type="video/mp4" />
            </Slider>

            <div className="pp2-grid-container">
                <div className="pp2-grid-item-row section-separator">
                    <div className="pp2-subtitle">overview</div>
                    <p className="pp2-description">
                    Giga Golf is a golf minigame created in Unity for the Spatial platform using Spatial Creator Toolkit. In the game, the player uses their avatar to kick a giant golfball around a 3-hole course. The player must navigate the course strategically, dodging obstacles and utilizing teleporters that add an extra layer of challenge to this unique spin on the classic game. 
                    </p>
                    <br />
                    <p className="pp2-role">Primary responsibilities: Game development, Game design.</p>
                
                </div>

                <div className="pp2-grid-item-row section-separator tags-grid">
                    <div className="pp2-subtitle">tools</div>

                    <div className="pp2-grid-container-five-col">
                        <div className="pp2-grid-item-col"><div className="tag">UNITY</div></div>
                        <div className="pp2-grid-item-col"><div className="tag">VISUAL SCRIPTING</div></div>
                        <div className="pp2-grid-item-col"><div className="tag">SPATIAL TOOLKIT</div></div>
                        <div className="pp2-grid-item-col"><div className="tag">GITHUB</div></div>
                    </div>
                </div>

                {/* C1R7 - Back */}
                <div class="pp2-grid-item-row back">
                    <div className="pp2-subtitle">
                    <a href="/" target="_self" className="pp2-back">back
                        <img src={Line} alt="" className="pp2-back-line"></img>
                    </a>
                    </div>
                </div>
            </div>
        </div>
    )
};